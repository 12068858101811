.button {
  width: 32px;
  height: 32px;
  border: 1px solid #65bc7b;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  cursor: pointer;
  margin-right: 12px;
}

.buttonIcon {
  margin-right: 10px;
  width: 1em;
  height: 1em;
}

.menuIcon {
  width: 48px;
  height: 48px;
}

.activeLang {
  background-color: #f5f5f5;
}
