.button {
  width: 48px;
  height: 48px;
  border: 2px solid #65bc7b;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  cursor: pointer;

  span {
    font-size: 1.2rem;
    color: #14343e;
  }
}

.menuContainer {
  border-radius: 10px;
}

.buttonIcon {
  margin-right: 10px;
}

.logoutIcon {
  color: #ff7d78;
}
