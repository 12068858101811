.header {
  font-weight: 700;
  font-size: 46px;
  line-height: 48px;
  text-align: center;
}

.input {
  background: #e0f1f5;
  border-radius: 10px;
  border: none;
  padding: 13px 30px;
  height: 60px;
  width: 100%;
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  appearance: none;
}

.errorMessage {
  font-weight: 300;
  font-size: 18px;
  line-height: 25px;
  color: #ff3434;
}
