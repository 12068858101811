@use "styles/base";

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  //justify-content: space-between;
  justify-content: center;
  color: #000;
  width: 100%;
  //padding: 20px 20px 0;
  background-color: #fff;
  height: 100px;
  box-shadow: 0 50px 40px -50px rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid #e0f1f5;

  @include base.responsive(1200px) {
    padding: 0;
    .headerContent {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  @include base.responsive {
    padding: 0;
    height: 80px;
  }

  .headerContent {
    height: 100%;
    max-width: 1440px;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 40px;
    //gap: 80px;
    //background: #14343e;
    box-shadow: 0 10px 20px -10px rgba(20, 52, 62, 0.07);
    border-radius: 20px;

    @include base.responsive {
      border-radius: 0;
    }

    .logo {
    }

    nav.nav {
      font-weight: 500;
      display: flex;
      flex-direction: row;
      //justify-content: space-around;
      justify-content: center;
      align-items: center;
      padding: 0;
      flex-grow: 1;
      align-self: stretch;
      min-height: 55px;
      gap: 30px;
      margin: 0 20px;
    }

    .spacer {
      flex: 1;
    }
  }
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 100px;
  transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
  font-size: 18px;
  line-height: 25px;
  font-weight: 300;
  //color: #ffffff;
  //flex: 1;
  text-align: center;
  padding: 15px 20px;
  //padding: 10px 0;
  word-break: keep-all;
  white-space: nowrap;
  cursor: pointer;

  &[data-active="true"] {
    background-color: #66bc7b;
    color: #fff;
  }

  &.login {
    flex: 0;
    border: 1px solid #e0f1f5;
    padding: 10px 20px;
    margin-right: 12px;
  }

  &.linkDisabled {
    color: #888888;
    cursor: default;
  }
}
