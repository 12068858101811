@use "styles/base";

.drop {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.61);
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 60px;
  background-color: #ffffff;
  position: absolute;
  inset: 1rem;
  height: fit-content;
  //width: fit-content;
  max-width: 850px;
  max-height: 100%;
  margin: auto;
  //overflow: auto;
  outline: none;
  padding: 80px 60px;
  box-shadow: 0 10px 20px -10px rgba(20, 52, 62, 0.07);
  border-radius: 20px;

  @include base.responsive {
    max-width: 100%;
    padding: 40px 20px;
    border-radius: 12px;
  }

  .closeButton {
    cursor: pointer;
    position: absolute;
    width: 48px;
    height: 48px;
    right: -20px;
    top: -20px;
    background: #14343e;
    border: 1px solid #e0f1f5;
    border-radius: 48px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      transition: transform 0.2s ease;
    }

    &:hover svg {
      transform: rotate(90deg);
    }

    @include base.responsive {
      width: 40px;
      height: 40px;
      right: -10px;
      top: -10px;
    }
  }
}
