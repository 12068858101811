.base {
  width: 100%;
  border-radius: 8px;
  background-color: #fff;
  //border: 1px solid #aaa;
  color: #444;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.75rem 1rem;
  position: relative;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  border: none;
  box-shadow: 0 10px 20px -10px rgba(20, 52, 62, 0.1);

  &.loading {
  }

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.white {
    background-color: #fff;
    border: 1px solid #aaa;
    color: #444;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  &.green {
    box-shadow: 0 10px 20px -10px rgba(20, 52, 62, 0.07);
    color: #fff;
    background: #65bc7b;

    &:hover {
      background: #4faa64;
    }
  }

  &.red {
    box-shadow: 0 10px 20px -10px rgba(20, 52, 62, 0.07);
    background: #ff7b77;
    color: #fff;

    &:hover {
      background: #ff5f5b;
    }
  }
}
