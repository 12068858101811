.modal {
  width: fit-content;
}

.content {
  font-weight: 500;
  font-size: 1.4rem;
}

.actions {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.destructIcon {
  font-weight: normal;
  margin-left: 10px;
}