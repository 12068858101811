$responsive-size: 768px;

@mixin responsive($breakpoint: $responsive-size) {
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}

@mixin responsiveMin($breakpoint: $responsive-size) {
  @media screen and (min-width: $breakpoint) {
    @content;
  }
}
